.button-upload {
    /* Reset OS-specfic / browser-specific default style (see https://ishadeed.com/article/styling-the-good-old-button/#the-default-styles) */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: rgba(255, 255, 255); /* to be replaced with your custom design */
    border: 1px solid currentColor; /* to be replaced with your custom design */
    /* Signify interactivity */
    border-radius: 4px; /* no physical button has a sharp corner; exact value to be replaced for your custom design */
    cursor: pointer;
    /* Match font style with the parent element */
    
    /* Make the button easy for touchscreen users to tap */
    min-height: 48px;
    min-width: 48px;
  }
  .button-upload:focus {
    outline-offset: 4px; /* make Chrome show a focus ring around the button */
  }
