.App {
  text-align: center;
  
}


.App-header {
  position: sticky;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  top: 10px;
  background-color: white;
  opacity: 90%;
  
}
.counter {
  visibility: hidden;
  
}

.App-link {
  color: #61dafb;
}
.logo {
  margin-top: 10px;
  margin-bottom: 20px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  opacity: 90%;
  
}
a{
  color: #8f5799;
  text-decoration: none;
}

a:hover{
  color: #6b3f73;
  text-decoration: underline;
}

#twoimages {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  
}




#prev {
  width: 300px;;
  height: 300px;
  object-position: center;
  object-fit: cover;
  background-size: cover;
  
}

.centercrop {
  height: 300px;
  width: 300px;
  object-position: center;
  object-fit: cover;
  background-size: cover;
}

.imagespacer {
  padding: 5px;
  margin: 10px;
}

